// Main
$body-color: $c-holly;
$main-font: 'Fira Sans';

:root {
  --main-color: #D31E3D;
  --main-color-dark: #B30E2B;
  --main-color-light: #ECEDF4;
  --details-color: #D31E3D;
  --promo-box-slider-bg: #2A357E;
  --promo-box-slider-sign-color: #D31E3D;
  --event-wro-bg: #ECEDF4;
  --event-wro-data-bg-color: #ECEDF4;
  --services-promo-box-details-color: #D31E3D;
  --services-promo-box-bg: #ECEDF4;
} 

$transition: all .4s ease;  