// SCSS Color Variables
// Named with this: http://chir.ag/projects/name-that-color

$c-black: #000000;
$c-white: #FFFFFF;

$c-anakiwa: #8DD8FF;
$c-azure: #316DB4;
$c-cararra: #EAE8E0;
$c-gull-gray: #94A1AC;
$c-holly: #001209;
$c-new-york-pink: #DB8383;
$c-pampas: #F3F2EC;
$c-punch: #DE3838;
$c-rhino: #313A62;
$c-sea-pink: #EC9090;
$c-sundown: #FFB4B4;
$c-sunglo: #E56464;
$c-timberwolf: #D5D2C9;

$c-cardinal: #D31E3D;
$c-shiraz: #B30E2B;
$c-athens-gray: #ECEDF4;
$c-blue-haze: #CFD1E5;
$c-blue-zodiac: #0B1A3A;
$c-silver-chalice: #B2B2B2; 