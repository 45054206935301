@use "../../../../assets/sass/abstract/_responsive.scss" as rwd;
@use "../../../../assets/sass/abstract/_mixins" as mixin;

$c-solitude: #e1efff;
$main-pointers: #2a357e;
$c-cardinal: #d31e3d;

//wskaźniki na prezydencie
.pointers {
  @include rwd.breakpoint(mobileUp) {
    margin-bottom: 50px;
  }

  @include rwd.breakpoint(mobile) {
    margin-bottom: 30px;
  }

  .pointers__top {
    margin-top: 26px;
  }

  .container {
    @include rwd.breakpoint(mobile) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &.stickyPointers {
    .pointers__wrapper {
      padding-top: 79px;
    }

    .pointers__top {
      @include mixin.position(fixed, 56px, 0, false, 0);
      margin-top: 0;
      z-index: 10;
      display: flex;
      justify-content: center;

      @include rwd.breakpoint(smallUp) {
        top: 60px;
      }
    }
  }

  &__head {
    @include rwd.breakpoint(mobile) {
      background-color: $main-pointers;
      padding-right: 4px;
    }
  }

  &__anchorsList {
    @include mixin.flexCenter();
    background-color: $main-pointers;
    height: 62px;
    color: $c-white;

    @include rwd.breakpoint(desktopsDown) {
      overflow: scroll;
      width: 100%;
      justify-content: unset;
    }
  }

  &__anchor {
    @include mixin.flexCenter();
    height: 100%;
    position: relative;
    transition: 0.4s;
    cursor: pointer;
    min-width: 150px;
    padding: 0 14px;

    @include rwd.breakpoint(mobileUp) {
      text-align: center;
    }

    @include rwd.breakpoint(mobile) {
      text-align: left;
      border: 3px solid $main-pointers;
      box-sizing: border-box;
    }

    & > * {
      cursor: pointer;
    }

    &:before {
      @include rwd.breakpoint(mobileUp) {
        @include mixin.position(absolute, false, false, 0, 0);
        content: "";
        height: 2px;
        background: $c-cardinal;
        width: 0;
        transition: 0.4s;
      }

      @include rwd.breakpoint(mobile) {
        display: none;
      }
    }

    &:hover,
    &.activeAnchor {
      &:before {
        width: 100%;
      }

      @include rwd.breakpoint(mobile) {
        background: $c-white;
        color: $main-pointers;

        & > * {
          color: $main-pointers;
        }
      }
    }

    &:first-of-type {
      &:after {
        display: none;
      }
    }

    &:after {
      @include rwd.breakpoint(mobileUp) {
        @include mixin.position(absolute, 50%, false, false, 0);
        content: "";
        transform: translateY(-50%);
        width: 1px;
        height: 43px;
        background: $c-silver;
      }

      @include rwd.breakpoint(mobile) {
        display: none;
      }
    }
  }

  &__anchorTxt {
    @include mixin.font(17px, 19px, 700, $c-white);
  }

  &__bottom {
    background: $main-pointers;

    @include rwd.breakpoint(mobileUp) {
      padding-left: 3px;
      padding-right: 3px;
      padding-bottom: 3px;
    }

    @include rwd.breakpoint(mobile) {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;
    }
  }

  &__groupTitleBox {
    @include mixin.flexCenter();
    min-height: 57px;
    background: $main-pointers;
    color: $c-white;
    text-align: center;
  }

  &__groupTitle {
    font-family: "Roboto", sans-serif;
    margin: 0;
    text-transform: uppercase;

    @include rwd.breakpoint(mobileUp) {
      @include mixin.font(34px, 40px, 700, $c-white);
    }

    @include rwd.breakpoint(mobile) {
      @include mixin.font(20px, 23px, 700, $c-white);
    }
  }

  &__projectsList {
    background: $c-white;
  }

  &__listItem {
    display: flex;
    flex-wrap: nowrap;
    border-top: dotted 1px $main-pointers;
    padding-bottom: 17px;

    @include rwd.breakpoint(mobileUp) {
      margin-left: 46px;
      margin-right: 43px;
      padding-top: 30px;
    }

    @include rwd.breakpoint(mobile) {
      flex-direction: column;
      align-items: unset;
      padding-top: 16px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &__imgWrap {
    @include rwd.breakpoint(mobileUp) {
      width: 295px;
      margin-right: 30px;
      min-width: 295px;
    }

    @include rwd.breakpoint(mobile) {
      min-width: unset;
      margin-right: 0;
      width: 100%;
      margin-bottom: 10px;
    }

    img {
      width: 100%;
      height: auto;

      @include rwd.breakpoint(mobile) {
        max-height: 170px;
        object-fit: cover;
      }
    }
  }

  &__contentWrap {
    flex: 1;
  }

  .pointersDetails {
    position: relative;

    & > * {
      font-family: "Roboto", sans-serif;
      color: $main-pointers;
    }

    &__titleItemBox {
      margin-left: 0;
      margin-right: 0;
      margin-top: -10px;

      @include rwd.breakpoint(mobileUp) {
        margin-bottom: 20px;
        padding-right: 70px;
      }

      @include rwd.breakpoint(mobile) {
        margin-bottom: 10px;
        padding-right: 0;
      }
    }

    &__titleLnk {
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &__title {
      font-family: "Roboto Slab", sans-serif;
      letter-spacing: 0em;
      margin: 0;
      padding: 0;
      text-align: left;
      width: fit-content;

      @include rwd.breakpoint(mobileUp) {
        @include mixin.font(34px, 40px, 400);
      }

      @include rwd.breakpoint(mobile) {
        @include mixin.font(24px, 32px, 600);
      }
    }

    .titleItemLnk {
      position: relative;
      display: inline-block;

      &.lnkBlock--blank {
        @include mixin.simpleLinkBlank;
        padding-right: 28px;

        &:after {
          color: $c-cardinal;

          @include rwd.breakpoint(mobileUp) {
            @include mixin.font(23px, 23px);
            top: -8px;
          }

          @include rwd.breakpoint(mobile) {
            @include mixin.font(19px, 19px);
            top: -6px;
          }
        }
      }
    }

    &__quote {
      @include mixin.font(18px, 25px, 400);
      background: $c-solitude;
      width: 100%;
      text-align: left;
      position: relative;
      display: block;
      font-family: "Roboto", sans-serif;
      font-style: italic;
      letter-spacing: 0em;

      @include rwd.breakpoint(mobileUp) {
        margin-bottom: 20px;
        padding: 3px 8px 5px 20px;
      }

      @include rwd.breakpoint(mobile) {
        font-size: 16px;
        margin-bottom: 14px;
        padding: 6px 22px 6px 9px;
      }

      & > * {
        color: $main-pointers;
      }

      .icon {
        @include mixin.position(absolute, -7px, false, false, 0);
        @include mixin.font(26px, false, 700);
        transform: translateX(-50%);

        @include rwd.breakpoint(mobile) {
          top: -13px;
          left: unset;
          right: -6px;
        }
      }
    }

    &__info {
      letter-spacing: 0px;
      text-align: left;

      @include rwd.breakpoint(mobileUp) {
        @include mixin.font(16px, 18px, 700);
      }

      @include rwd.breakpoint(mobile) {
        @include mixin.font(15px, 17px, 700);
      }
    }

    &__chart {
      margin-left: -15px;

      .highcharts-figure {
        margin: 0;
      }
    }

    &__additionalInfo span {
      @include mixin.font(12px, 16px, 300);
      letter-spacing: 0px;
      text-align: left;
    }

    &__sumBox {
      @include mixin.position(absolute, 0, 0);

      @include rwd.breakpoint(mobile) {
        right: 16px;
        top: -76px;
        background: $c-white;
        padding: 5px 8px;
      }

      .sumTxt {
        letter-spacing: 0px;
        text-align: center;
        display: block;

        @include rwd.breakpoint(mobileUp) {
          @include mixin.font(16px, 22px, 400);
        }

        @include rwd.breakpoint(mobile) {
          @include mixin.font(12px, 14px, 400);
        }
      }
    }

    &__nb {
      display: block;
      font-family: "Roboto Slab";
      letter-spacing: 0em;
      text-align: center;

      @include rwd.breakpoint(mobileUp) {
        @include mixin.font(34px, 45px, 800);
      }

      @include rwd.breakpoint(mobile) {
        @include mixin.font(20px, 26px, 800);
      }
    }

    &__total {
      display: block;
      letter-spacing: 0px;
      text-align: center;

      @include rwd.breakpoint(mobileUp) {
        @include mixin.font(20px, 22px, 700);
      }

      @include rwd.breakpoint(mobile) {
        @include mixin.font(16px, 18px, 700);
      }
    }
  }

  .highcharts-label text {
    font-size: 16px !important;
    line-height: 18px !important;
    font-family: "Roboto", sans-serif;
  }
} 