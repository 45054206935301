@use "../../../../assets/sass/abstract/_responsive.scss"as rwd;
@use "../../../../assets/sass/abstract/_mixins.scss"as mixin;

// Consts
$services-details-color: $c-anakiwa;

body[data-main="prezydent"] {
  .mainFooter {
    margin-top: 0;
  }

  .mainHeader .stickyLogo {
    top: 5px;
  }

  .mainHeader .stickyLogo>img {
    width: 140px;
    height: 50px;
  }
}

// Promo services box
.servicesPromoBox {
  margin-top: 0;
  background-color: $c-athens-gray;

  @include rwd.breakpoint(phonesDown) {
    padding-left: 0;
    padding-right: 0;
  }

  .rightSide {
    &__content .lnkBtn {
      border-color: $services-details-color;
    }
  }

  .topBar.projectsSliderAlone {
    @include rwd.breakpoint(phonesDown) {
      margin-left: 0;
    }
  }

  .projectsSlider .boxLnk {
    color: $c-blue-zodiac !important;
  }

  .splide.arrowsLight .splide__arrow:before {
    color: $c-blue-haze;
  }

  .splide .splide__arrow:hover:before,
  .splide.arrowsLight .splide__arrow:focus:before {
    color: var(--main-color);
  }

  .value {
    color: $c-white;
  }
}

//iconSlider
.iconSlider {
  background-color: $c-athens-gray;
  margin-top: 0;
  margin-bottom: 0;
  @include mixin.paddingTopBottom();

  .info__link {
    background-color: $c-white;
  }

  .splide .splide__arrow:before {
    color: $c-blue-haze;
  }

  .splide .splide__arrow:hover:before,
  .splide.arrowsLight .splide__arrow:focus:before {
    color: var(--main-color);
  }
}

.iconSlider,
.servicesPromoBox {
  .splide.arrowsMobileSlider .splide__arrow {
    @include rwd.breakpoint(phonesDown) {
      background-color: $c-white;
    }
  }
}

.pageInfoBar__wroclawpl img {
  @include rwd.breakpoint(tabletmobile) {
    width: 72px;
  }
}

.promoBoxSliderSection {
  margin: 0 !important;
}

.promoBoxSliderSection .promoSlider,
.iconSlider {
  .splide__arrow {

    &:hover,
    &:focus {
      &::before {
        outline: none;
      }
    }
  }
}

//404
.box404 {
  .box404__logo {
    display: none;
  }
}

span.bbGallery__img__author {
  font-size: 9px !important;
  line-height: 11px !important;
  font-weight: 600 !important;
  color: rgba(255, 255, 255, 0.8) !important;
}

.s-shortsYtSliderPopUp {
  @include rwd.breakpoint(mobile) {
    margin-top: 26px;
  }
}

.imageText.withoutBackgroundColor {
  @include rwd.breakpoint(tabletmobile) {
    margin-top: -5px;
  }

  .sectionTitle {
    @include rwd.breakpoint(mobile) {
      margin-bottom: 10px;
    }
  }
}