@use "../../../../assets/sass/abstract/_responsive.scss" as rwd;
@use "../../../../assets/sass/abstract/_mixins" as mixin;

$c-deep-sapphire: #0f2f74;
$c-silver-chalice: #b2b2b2;
$category-lnk-bg-color: #0f2f74;
$heading-color: #313743;
$icon-color: #313743;
$precent-color: #313743;
$program-bg-color: #edf6f9;
$program-tile-bg-color: #e4eff4;
$program-txt-color: #2a357e;
$progress-bar-bg-color: #f7f6f6;
$progress-val-color: #313743;
$secendary-color: #0f2f74;

.programSection {
  font-family: roboto, sans-serif;
  margin-bottom: 20px;

  .programTopBox {
    margin: 50px 0;

    @include rwd.breakpoint(mobile) {
      margin: 40px 0 30px;
    }

    .pageBoxHeader {
      .txtTitle {
        @include mixin.font(18px, 32px, 700);
        margin-bottom: 0;
      }
    }

    .descriptionBox {
      @include mixin.font(18px, 32px);
      margin: 0 auto 50px;
      overflow: hidden;
      position: relative;

      &.expanded {
        &:before {
          height: 55px;
        }
      }
    }

    .selectWrapper {
      display: flex;
      justify-content: flex-end;

      .tasksBox {
        position: relative;
      }

      select {
        padding: 15px 50px 15px 20px;
        background-color: $c-white;
        border: 1px solid $c-silver-chalice;
        @include mixin.font(16px);
        -webkit-appearance: none;

        &:focus {
          & + .icon {
            transform: rotate(180deg);
          }
        }
      }

      .icon {
        @include mixin.position(absolute, 20px, 20px);
        @include mixin.font(16px, false, false, $secendary-color);
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
      }
    }

    &.quarters {
      .descriptionBox strong {
        font-weight: 900;
      }

      .categoryListWithIcon {
        li {
          @include rwd.breakpoint(mobile) {
            width: 50%;
          }
        }
      }
    }
  }

  .categoryListWithIcon {
    padding-left: 0;
    flex-wrap: wrap;

    li {
      flex-grow: 1;

      @include rwd.breakpoint(mobile) {
        width: 25%;
      }
    }

    a {
      @include mixin.flexCenter();

      transition: all 0.3s ease-in-out;

      height: 100px;
      text-decoration: none;

      @include rwd.breakpoint(mobile) {
        height: 70px;
      }

      .icon {
        @include mixin.font(40px);
        color: $icon-color;
        transition: all 0.3s ease-in-out;

        @include rwd.breakpoint(mobile) {
          @include mixin.font(30px);
        }
      }

      &:hover {
        background-color: $category-lnk-bg-color;

        .icon,
        h2 {
          color: $c-white;
        }
      }

      .txtTitle {
        @include rwd.breakpoint(mobile) {
          @include mixin.font(14px);
        }
      }

      &.active {
        background-color: $category-lnk-bg-color;

        .icon {
          color: $c-white;
        }

        h2 {
          color: $c-white;
        }
      }
    }
  }

  ul.categoryContent {
    padding-left: 0;
    margin-top: 0;
  }

  // CATEGORY CONTENT
  .categoryContent {
    display: none;

    &.activeCategory {
      display: block;
    }

    .categoryHeader {
      display: flex;
      align-items: center;
      margin: 80px 0 10px;

      @include rwd.breakpoint(mobile) {
        margin-top: 55px;
      }

      .icon {
        color: var(--main-color);
        font-size: 42px;
        margin-right: 15px;

        @include rwd.breakpoint(mobile) {
          font-size: 34px;
        }
      }

      .txtCategoryHeading {
        color: var(--main-color);
        @include mixin.font(30px, 36px);
        margin: 0;

        @include rwd.breakpoint(mobile) {
          @include mixin.font(20px, 25px);
        }
      }
    }

    .categoryTopDescription {
      max-width: 1050px;
      margin: 0 auto;
      text-align: center;

      .txtTitle {
        @include mixin.font(25px, 30px);

        @include rwd.breakpoint(mobile) {
          @include mixin.font(20px, 25px);
        }
      }

      > * {
        color: $c-black;
      }

      .descriptionBox {
        padding-bottom: 55px;
        position: relative;
        height: 107px;
        overflow: hidden;

        &.expanded {
          &:before {
            height: 55px;
          }
        }

        &:before {
          @include mixin.position(absolute, false, false, 0, 0);
          content: "";
          width: 100%;
          height: 90%;
          background: -moz-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 1) 100%
          );
          background: -webkit-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 1) 100%
          );
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 1) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
          transition: all 0.3s ease-in-out;
        }
      }
    }

    .slideTxt {
      p {
        margin: 0;
        @include mixin.font(18px, 32px);
      }
    }
  }

  // CATEGORY SINGLE BOX
  .projectSingleBox {
    margin-top: 20px;

    &.expanded {
      .topDetailsBar {
        .descBox {
          border-bottom: none;
        }

        .btn {
          .icon {
            transform: rotate(180deg);
          }
        }
      }
    }

    .firstColumn {
      width: 300px;

      @include rwd.breakpoint(tablet) {
        width: 200px;
      }
    }

    .bottomDetails .firstColumn {
      width: 299px;

      @include rwd.breakpoint(tablet) {
        width: 199px;
      }
    }

    // TOP DETAILS
    .topDetailsBar {
      cursor: pointer;

      .txtTitle {
        @include mixin.font(24px);

        @include rwd.breakpoint(mobile) {
          @include mixin.font(18px);
        }
      }

      .boxWithIcon {
        background-color: $program-tile-bg-color;
        border: 1px solid $border-color;
        min-height: 220px;

        @include rwd.breakpoint(mobile) {
          width: 100%;
          min-height: auto;
          padding: 12px 0;
        }

        .icon {
          @include mixin.font(90px);
          color: $icon-color;

          @include rwd.breakpoint(mobile) {
            @include mixin.font(60px);
          }
        }

        img {
          max-width: 90px;
          max-height: 90px;
          height: auto;

          @include rwd.breakpoint(mobile) {
            max-height: 60px;
            max-width: 60px;
          }
        }

        .txtTaskNumber {
          @include mixin.font(35px, 35px, 700, $heading-color);

          @include rwd.breakpoint(mobile) {
            @include mixin.font(24px, 24px);
          }
        }
      }

      .descBox {
        flex: 1;
        padding: 16px 45px 16px 30px;
        border: 1px solid $border-color;
        border-right: none;

        @include rwd.breakpoint(mobile) {
          padding: 14px 20px;
        }
      }

      .btn {
        min-width: initial;
        width: 52px;
        @include mixin.flexCenter();

        .icon {
          font-size: 10px;
          transition: all 0.3s ease-in-out;
          transform: rotate(0deg);
        }
      }

      .descMain {
        flex: 1;

        @include rwd.breakpoint(smallUp) {
          align-items: center;
          display: flex;
        }

        @include rwd.breakpoint(mobile) {
          flex: auto;
          width: 100%;
        }

        .durationYears {
          background-color: $program-bg-color;
          border-radius: 4px;
          @include mixin.font(16px, 18px, 400, $program-txt-color);
          padding: 5px 10px;
          margin-left: 10px;
          display: inline-block;

          @include rwd.breakpoint(tabletmobile) {
            margin-top: 10px;

            &:first-of-type {
              margin-left: 0;
            }
          }
        }

        .txtTitle {
          margin: 0 10px 0 0;
          color: $secendary-color;
          @include mixin.font(18px, 22px);

          // @include rwd.breakpoint(mobile) {
          //   margin-top: 10px;
          // }
        }

        .descriptionBox {
          max-width: 630px;
          margin-bottom: 35px;

          @include rwd.breakpoint(mobile) {
            margin-bottom: 0;
          }
        }

        .progressBar {
          height: 14px;
          position: relative;
          background-color: $progress-bar-bg-color;
          margin-bottom: 10px;

          .progressLine {
            @include mixin.position(absolute, 0, false, false, 0);
            height: 100%;
            background-color: var(--main-color);

            &:after,
            &:before {
              @include mixin.position(absolute, 50%);
              content: "";
              transform: translateY(-50%);
            }

            &:before {
              @include mixin.makeCircle(34px, $secendary-color);
              right: -27px;
            }

            &:after {
              @include mixin.makeCircle(18px, $progress-bar-bg-color);
              right: -19px;
            }
          }
        }

        .progressValueBox {
          position: relative;
        }

        .progressVal {
          @include mixin.font(24px, 28px, 700, $progress-val-color);

          &.current {
            position: absolute;
            min-width: 34px;
            text-align: center;
          }
        }
      }

      .descPrecentBox {
        margin-left: 40px;

        span {
          display: block;
          text-align: center;
        }

        .precentVal {
          @include mixin.font(48px, 52px, 700, $precent-color);
          margin-bottom: 10px;
        }
      }
    }

    // BOTTOM DETAILS
    .bottomDetails {
      display: none;

      .txtTitle {
        @include mixin.font(15px);
        margin-top: 0;
        margin-bottom: 20px;
      }

      .left,
      .right {
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }

      .left {
        padding: 30px 20px;

        @include rwd.breakpoint(mobile) {
          display: none;
        }
      }

      .right {
        padding: 15px 30px 30px;
        width: 100%;
        border-right: 1px solid $border-color;

        @include rwd.breakpoint(mobile) {
          padding: 15px;
          width: 100%;
        }
      }

      .newsListBox {
        margin-top: 10px;
      }

      ul:not(.listWithLnkBlock):not(.slick-dots):not(.programList):not(
          .tileLineList
        ) {
        padding-left: 20px;
        list-style: disc;

        li {
          position: relative;
          margin-bottom: 10px;

          &::marker {
            font-size: 1.4rem;
            color: var(--main-color);
          }

          > ul {
            margin: 20px 0;

            li {
              &::marker {
                color: #d6d6d6;
              }

              &::after {
                display: none;
              }
            }
          }
        }
      }
    }

    .listWithLnkBlock {
      padding: 0 5px 20px;

      &.goSlick {
        .lnkBoxWithPhoto {
          margin: 0 10px;
        }
      }
    }

    .slick-dots {
      bottom: -30px;
    }

    .lnkBoxWithPhoto {
      .photoWrapper {
        height: 180px;
        overflow: hidden;

        @include rwd.breakpoint(mobile) {
          height: auto;
        }
      }
    }
  }

  .quartersBody {
    .categoryContent {
      display: block;
    }
  }

  // SHOW MORE INFO
  .displayMoreInfo {
    @include mixin.centerer(true, false);
    @include mixin.makeCircle(26px, $secendary-color);
    bottom: 15px;
    color: $c-white;
    display: inline-block;
    font-size: 8px;
    line-height: 26px;
    transform: rotate(0deg);
    transform: translate(-50%, 0) rotate(0deg);
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: var(--main-color);
    }

    &.active {
      transform: translate(-50%, 0) rotate(180deg);
    }
  }
}

//kwartały wrocławia prezydent sekcja  programTop
.programTop {
  @include rwd.breakpoint(mobileUp) {
    margin: 50px 0;
  }

  @include rwd.breakpoint(mobile) {
    margin: 40px 0 30px;
  }

  .descriptionBox {
    text-align: center;
    max-width: 1050px;
    margin: 0 auto 50px;
    overflow: hidden;
    position: relative;
  }

  &.expanded:before {
    height: 55px;
  }

  .selectWrapper {
    display: flex;
    justify-content: flex-end;

    .tasksBox {
      position: relative;
    }

    select {
      padding: 15px 50px 15px 20px;
      background-color: $c-white;
      border: 1px solid $c-silver-chalice;
      font-size: 16px;
      -webkit-appearance: none;

      &:focus + .icon {
        transform: rotate(180deg);
      }
    }

    .icon {
      @include mixin.position(absolute, 20px, 20px);
      font-size: 10px;
      color: $c-deep-sapphire;
      transform: rotate(0deg);
      transition: 0.3s;
    }
  }

  &.quarters {
    .descriptionBox strong {
      font-weight: 900;
    }

    .categoryListWithIcon li {
      @include rwd.breakpoint(mobile) {
        width: 50%;
      }
    }
  }
}
