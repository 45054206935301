@use "../../../../assets/sass/abstract/_responsive.scss" as rwd;
@use "../../../../assets/sass/abstract/_mixins" as mixin;

$c-alto: #cfcfcf;
$c-bright-gray: #313743;
$c-catskill-white: #edf1f6;
$c-dove-gray: #656565;
$c-emperor: #4f4f4f;
$c-fern: #65ab55;
$c-flamingo: #f55a3a;
$c-la-palma: #17a026;
$c-mine-shaft-dark: #232323;
$c-orange-peel: #ef9c00;
$c-scarlet: #f52314;
$c-seashell: #f1f1f1;
$c-silver: #cccccc;
$c-tower-gray: #afbcc2;
$c-wild-sand: #f5f5f5;
$c-cardinal: #d31e3d; 

.quartersClass {
  @include rwd.breakpoint(mobileUp) {
    margin-bottom: 100px;
  }

  @include rwd.breakpoint(mobile) {
    margin-bottom: 50px;
  }

  ul.categoryContent {
    padding-left: 0;
    margin-top: 0;
  }

  .categoryContent {
    display: none;

    &.activeCategory {
      display: block;
    }

    .categoryHeader {
      align-items: center;
      display: flex;
      margin: 80px 0 10px;

      @include rwd.breakpoint(mobile) {
        margin-top: 55px;
      }

      .icon {
        color: $c-cardinal;
        margin-right: 15px;

        @include rwd.breakpoint(mobileUp) {
          font-size: 42px;
        }

        @include rwd.breakpoint(mobile) {
          font-size: 34px;
        }
      }

      .txtCategoryHeading {
        margin: 0;
        color: $c-cardinal;

        @include rwd.breakpoint(mobileUp) {
          @include mixin.font(30px, 36px);
        }

        @include rwd.breakpoint(mobile) {
          @include mixin.font(20px, 25px);
        }
      }
    }

    .categoryTopDescription {
      margin: 0 auto;
      max-width: 1050px;
      text-align: center;

      .txtTitle {
        @include rwd.breakpoint(mobileUp) {
          @include mixin.font(25px, 30px);
        }

        @include rwd.breakpoint(mobile) {
          @include mixin.font(20px, 25px);
        }
      }

      & > * {
        color: $c-black;
      }

      .descriptionBox {
        padding-bottom: 55px;
        position: relative;
        height: 107px;
        overflow: hidden;

        &:before {
          @include mixin.position(absolute, false, false, 0, 0);
          content: "";
          width: 100%;
          height: 90%;
          background: -moz-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            white 100%
          );
          background: -webkit-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            white 100%
          );
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            white 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#ffffff",GradientType=0 );
          transition: 0.3s;
        }

        &.expanded:before {
          height: 55px;
        }
      }
    }

    .slideTxt p {
      margin: 0;
    }

    &.tasks100 {
      .categoryTopDescription .displayMoreInfo {
        display: none;
      }

      .projectSingleBox {
        margin-top: 20px;

        .topDetailsBar {
          .boxWithIcon {
            min-height: initial;
            padding: 20px 0;
            border: 1px solid $c-alto;

            @include rwd.breakpoint(mobile) {
              width: 65px;
            }
          }

          .descBox {
            padding: 15px 30px;
            align-items: center;

            @include rwd.breakpoint(mobile) {
              flex: 1;
              border-left: 0;
              border-top: 1px solid $c-alto;
              border-right: 1px solid $c-alto;
              padding: 15px;
            }

            .txtTitle {
              margin-bottom: 0;

              @include rwd.breakpoint(mobileUp) {
                @include mixin.font(20px, 30px);
                padding: 15px 15px 15px 0;
              }

              @include rwd.breakpoint(mobile) {
                @include mixin.font(16px, 22px);
                margin-top: 0;
                padding: 0;
              }
            }
          }
        }

        .bottomDetails {
          .right {
            @include rwd.breakpoint(mobileUp) {
              width: calc(100% - 99px);
            }

            @include rwd.breakpoint(mobile) {
              width: 100%;
            }
          }
        }
      }
    }

    &.term {
      .projectSingleBox {
        .topDetailsBar {
          .descMain {
            .flexContent {
              @include mixin.flexValue(center);

              .txtTitle {
                @include rwd.breakpoint(mobileUp) {
                  flex: 1;
                }

                @include rwd.breakpoint(mobile) {
                  width: 100%;
                  flex: auto;
                }
              }

              .txtStatus {
                display: inline-block;
                font-weight: 700;
                margin-left: 30px;
                padding: 7px 5px;
                width: 116px;
                text-align: center;
                border: 2px solid $c-tower-gray;
                color: $c-bright-gray;

                @include rwd.breakpoint(mobile) {
                  margin: 14px 0 0 0;
                  font-size: 12px;
                  width: initial;
                  padding: 4px 8px;
                }

                &.status-19167 {
                  border-color: $c-orange-peel;
                }

                &.status-19166 {
                  border-color: $c-fern;
                }
              }
            }
          }
        }
      }
    }
  }

  .projectSingleBox {
    margin-top: 20px;
    margin-bottom: 0;

    .topDetailsBar {
      cursor: pointer;

      @include rwd.breakpoint(mobile) {
        flex-wrap: wrap;
      }

      .txtTitle {
        @include rwd.breakpoint(mobileUp) {
          font-size: 24px;
        }

        @include rwd.breakpoint(mobile) {
          font-size: 18px;
        }
      }

      .btn {
        @include mixin.flexCenter();
        min-width: initial;
        background-color: var(--main-color);

        &:hover,
        &:focus {
          @include rwd.breakpoint(smallUp) {
            background-color: var(--main-color-darken);
          }
        }

        @include rwd.breakpoint(mobileUp) {
          width: 52px;
        }

        @include rwd.breakpoint(mobile) {
          width: 100%;
          height: 36px;
        }

        .icon {
          transition: all 0.3s ease-in-out;
          font-size: 20px;
          transform: rotate(0deg);
        }

        &.loading {
          position: relative;

          &:before {
            @include mixin.centerer();
            content: "";
            background-image: url("https://www.wroclaw.pl/beta2/themes/img/loading-white.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            z-index: 102;
          }

          .icon {
            display: none;
          }
        }
      }

      .boxWithIcon {
        background-color: $c-catskill-white;
        border: 1px solid $c-alto;

        @include rwd.breakpoint(mobileUp) {
          min-height: 220px;
        }

        @include rwd.breakpoint(mobile) {
          width: 100%;
          min-height: auto;
          padding: 12px 0;
        }

        .icon {
          color: $c-bright-gray;

          @include rwd.breakpoint(mobileUp) {
            font-size: 90px;
          }

          @include rwd.breakpoint(mobile) {
            font-size: 60px;
          }
        }

        img {
          height: auto;

          @include rwd.breakpoint(mobileUp) {
            max-width: 90px;
            max-height: 90px;
          }

          @include rwd.breakpoint(mobile) {
            max-height: 60px;
            max-width: 60px;
          }
        }

        .txtTaskNumber {
          @include mixin.font(35px, 35px, 700, $c-bright-gray);
          font-family: "PT Serif", serif;

          @include rwd.breakpoint(mobile) {
            @include mixin.font(24px, 24px);
          }
        }
      }

      .descBox {
        border-right: none;
        border: 1px solid $c-alto;
        flex: 1;
        padding: 16px 45px 16px 30px;

        @include rwd.breakpoint(mobile) {
          flex: auto;
          width: 100%;
          padding: 15px;
          border-top: 0;
          border-left: 1px solid $c-alto;
          border-right: 1px solid $c-alto;
        }
      }

      .descMain {
        @include rwd.breakpoint(mobileUp) {
          flex: 1;
        }

        @include rwd.breakpoint(mobile) {
          flex: auto;
          width: 100%;
        }

        .txtTitle {
          @include mixin.font(18px, 22px, false, $c-deep-sapphire);
          margin: 0;

          @include rwd.breakpoint(mobile) {
            margin-top: 10px;
          }
        }

        .descriptionBox {
          max-width: 630px;

          @include rwd.breakpoint(mobileUp) {
            margin-bottom: 35px;
          }

          @include rwd.breakpoint(mobile) {
            margin-bottom: 0;
          }
        }

        .progressBar {
          height: 14px;
          position: relative;
          background-color: $c-wild-sand;
          margin-bottom: 10px;

          .progressLine {
            @include mixin.position(absolute, 0, false, false, 0);
            height: 100%;
            background-color: $c-cardinal;

            &:after,
            &:before {
              @include mixin.position(absolute, 50%);
              content: "";
              border-radius: 50%;
              transform: translateY(-50%);
            }

            &:before {
              width: 34px;
              height: 34px;
              background-color: $c-deep-sapphire;
              right: -27px;
            }

            &:after {
              width: 18px;
              height: 18px;
              background-color: $c-wild-sand;
              right: -19px;
            }
          }
        }

        .progressValueBox {
          position: relative;
        }

        .progressVal {
          @include mixin.font(24px, 28px, 700, $c-bright-gray);
          font-family: "PT Serif", serif;

          &.current {
            position: absolute;
            min-width: 34px;
            text-align: center;
          }
        }
      }

      .descPrecentBox {
        margin-left: 40px;

        span {
          display: block;
          text-align: center;
        }

        .precentVal {
          @include mixin.font(48px, 52px, 700, $c-bright-gray);
          font-family: "PT Serif", serif;
          margin-bottom: 10px;
        }
      }
    }

    .firstColumn {
      width: 300px;

      @include rwd.breakpoint(tablet) {
        width: 200px;
      }
    }

    .bottomDetails {
      display: none;

      .firstColumn {
        width: 299px;

        @include rwd.breakpoint(tablet) {
          width: 199px;
        }
      }

      .txtTitle {
        font-size: 15px;
        margin-top: 0;
        margin-bottom: 10px;
      }

      .left,
      .right {
        border-bottom: 1px solid $c-alto;
        border-left: 1px solid $c-alto;
      }

      .left {
        padding: 30px 20px;

        @include rwd.breakpoint(mobile) {
          display: none;
        }
      }

      .right {
        padding: 15px 30px 30px;
        border-right: 1px solid $c-alto;

        @include rwd.breakpoint(smallUp) {
          width: 100%;
        }

        @include rwd.breakpoint(tablet) {
          width: calc(100% - 199px);
        }

        @include rwd.breakpoint(mobile) {
          padding: 15px;
          width: 100%;
        }
      }

      .newsListBox {
        margin-top: 10px;
      }

      ul:not(.listWithLnkBlock):not(.slick-dots):not(.programList):not(
          .tileLineList
        ) {
        padding-left: 0;

        li {
          position: relative;
          padding-left: 15px;
          margin-bottom: 10px;

          &:before {
            @include mixin.position(absolute, 8px, false, false, 0);
            @include mixin.makeCircle(6px, $c-cardinal);
            content: "";
          }

          & > ul {
            margin: 20px 0;

            li:before {
              background-color: $c-alto;
            }
          }
        }
      }
    }

    .listWithLnkBlock {
      padding: 0 5px 20px;

      &.goSlick {
        .lnkBoxWithPhoto {
          margin: 0 10px;
        }
      }
    }

    .slick-dots {
      bottom: -30px;
    }

    .lnkBoxWithPhoto {
      .photoWrapper {
        overflow: hidden;

        @include rwd.breakpoint(mobileUp) {
          height: 180px;
        }

        @include rwd.breakpoint(mobile) {
          height: auto;
        }
      }
    }

    &.expanded {
      .topDetailsBar {
        .descBox {
          border-bottom: none;
        }

        .btn .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .quartersBody {
    .categoryContent {
      display: block;
    }

    .projectSingleBox {
      @include rwd.breakpoint(mobile) {
        margin-top: 35px;
      }

      .topDetailsBar {
        .descBox {
          @include rwd.breakpoint(mobileUp) {
            padding-bottom: 30px;
          }

          @include rwd.breakpoint(mobile) {
            padding-bottom: 15px;
          }
        }

        .txtTitle {
          @include mixin.font(26px, 36px, false, $c-black);
          margin-bottom: 15px;

          @include rwd.breakpoint(mobile) {
            @include mixin.font(17px, 22px);
            margin: 3px 0 7px;
          }
        }

        .highlight {
          background-color: $c-cardinal;
          color: $c-white;
        }

        .btn {
          @include rwd.breakpoint(mobileUp) {
            width: 52px;
          }

          @include rwd.breakpoint(mobile) {
            width: 100%;
          }

          .icon {
            font-size: 12px;
          }
        }

        &.flexRow {
          .projectData {
            .txtProjectData {
              @include mixin.flexCenter();
              padding-right: 15px;
              padding-left: 15px;
            }
          }

          .txtCategoryLabel {
            span {
              text-align: center;
            }
          }

          .projectNumber {
            @include rwd.breakpoint(mobileUp) {
              min-height: 160px;
            }

            @include rwd.breakpoint(mobile) {
              min-height: 47px;
            }
          }

          .descBox {
            padding-bottom: 16px;

            @include rwd.breakpoint(mobileUp) {
              border-left: none;
            }

            @include rwd.breakpoint(mobile) {
              border-left: 1px solid $c-alto;
            }
          }

          .flexContent {
            @include mixin.flexValue(center, space-between);
          }

          .txtTitle {
            margin-bottom: 0;

            @include rwd.breakpoint(mobileUp) {
              flex: 1;
            }

            @include rwd.breakpoint(mobile) {
              flex: auto;
              width: 100%;
              text-align: center;
            }
          }

          .projectData {
            @include rwd.breakpoint(mobileUp) {
              display: block;
              width: 190px;
              margin-left: 40px;
            }

            @include rwd.breakpoint(mobile) {
              @include mixin.flexValue();
              width: 100%;
              margin-left: 0;
              margin-top: 7px;
            }

            li {
              @include rwd.breakpoint(mobileUp) {
                margin: 0 0 8px;
                width: 100%;
              }

              @include rwd.breakpoint(mobile) {
                width: 100%;
                margin: 8px 0 0;
              }

              &:last-of-type {
                margin-bottom: 0;
              }
            }

            .txtProjectData {
              @include mixin.font(12px, 18px, false, $c-black);
              @include mixin.position(static, unset);
              width: 100%;
              text-transform: unset;
              background-color: transparent;
              white-space: normal;

              @include rwd.breakpoint(mobile) {
                @include mixin.flexCenter();
                height: 100%;
              }
            }
          }
        }

        button {
          border: none;
          cursor: pointer;
        }
      }

      .projectNumber {
        @include mixin.font(48px, false, 700);
        background-color: $c-catskill-white;
        border: 1px solid $c-alto;
        font-family: "PT Serif", serif;

        @include rwd.breakpoint(mobileUp) {
          width: 130px;
          min-height: 185px;
        }

        @include rwd.breakpoint(mobile) {
          width: 100%;
          min-height: initial;
          font-size: 40px;
          padding: 5px;
        }
      }

      .projectData {
        @include mixin.flexValue();
        margin: 0;
        padding-left: 0;

        @include rwd.breakpoint(mobile) {
          justify-content: space-between;
        }

        li {
          @include rwd.breakpoint(mobileUp) {
            margin-right: 12px;
          }

          @include rwd.breakpoint(mobile) {
            margin-right: 0;

            &:first-of-type,
            &:nth-of-type(2) {
              width: calc(50% - 3px);
            }

            &:last-of-type {
              width: 100%;
              margin-top: 6px;
            }
          }
        }

        .txtProjectData {
          display: inline-block;
          font-weight: 700;
          border: 1px solid;
          line-height: 18px;
          text-align: center;

          @include rwd.breakpoint(mobileUp) {
            padding: 6px 10px;
            min-width: 165px;
          }

          @include rwd.breakpoint(mobile) {
            padding: 3px 5px;
            width: 100%;
            font-size: 12px;
            min-width: inherit;
          }
        }

        .txtPeriod {
          color: $c-cardinal;
          border-color: $c-cardinal;
        }

        .txtStatus {
          border-color: $c-orange-peel;

          &.statusDone,
          &.status-19215 {
            border-color: $c-la-palma;
            color: $c-la-palma;
          }
        }

        .txtCategoryLabel {
          border-color: $c-black;

          .icon {
            font-size: 18px;
            color: $c-black;
            margin-right: 9px;
          }
        }
      }

      .bottomDetails {
        .firstColumn {
          width: 129px;
        }

        .right {
          padding: 0;

          @include rwd.breakpoint(mobileUp) {
            width: calc(100% - 129px);
          }

          @include rwd.breakpoint(mobile) {
            width: 100%;
          }

          &.noTasks {
            .dataContent {
              @include rwd.breakpoint(mobileUp) {
                padding-right: 30px;
              }

              @include rwd.breakpoint(mobile) {
                padding-right: 15px;
              }
            }
          }
        }

        .dataContent {
          @include rwd.breakpoint(mobileUp) {
            flex: 1;
            padding: 30px 15px 30px 30px;
          }

          @include rwd.breakpoint(mobile) {
            flex: auto;
            padding: 15px;
          }
        }

        .boxTimeLine {
          ul {
            padding-left: 0;

            li {
              &::before {
                display: none;
              }
            }
          }

          .tileLineList {
            margin-top: 35px;

            li {
              padding-bottom: 35px;
              margin-bottom: 5px;
              background-position: top 0 left 11px;

              .txtLineTitle {
                @include mixin.font(16px, 22px, 400);
                margin-top: -4px;
              }

              .iconStat {
                padding-bottom: 5px;
              }

              &.current {
                .txtLineTitle,
                .txtDate {
                  color: $c-cardinal;
                  font-weight: 700;
                }
              }
            }
          }
        }

        .txtTitle {
          @include mixin.font(20px, 24px, false, $c-black);
          margin-bottom: 12px;
        }

        .descriptionBox {
          &.small {
            line-height: 26px;
          }
        }

        .listWithLnkBlock {
          @include rwd.breakpoint(mobile) {
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }

        .newsListBox {
          .txtTitle {
            @include mixin.font(15px, 18px, 600);
            font-family: "Poppins", sans-serif;
            margin-bottom: 2px;
          }

          .txtDate {
            color: $c-black;
          }
        }

        .program {
          @include rwd.breakpoint(mobile) {
            padding: 0;

            .boxTimeLine {
              margin-top: 0;
              padding: 20px 10px;

              .tileLineList {
                li {
                  .txtLineTitle {
                    @include mixin.font(15px, 21px);
                    margin-bottom: 7px;
                  }
                }
              }
            }
          }
        }
      }

      &.expanded {
        .topDetailsBar {
          &.flexRow {
            .descBox {
              border-bottom: 1px solid $c-alto;
            }
          }
        }
      }
    }

    &.lastVer {
      .projectSingleBox {
        .topDetailsBar {
          .boxWithIcon {
            width: 127px;
          }

          .descBox {
            @include rwd.breakpoint(mobileUp) {
              border-left: none;
            }
          }
        }

        .bottomDetails {
          .right {
            @include rwd.breakpoint(mobileUp) {
              padding: 20px 20px 0 20px;
            }
          }
        }

        &.expanded {
          .topDetailsBar {
            .descBox {
              border-bottom: 1px solid $c-alto;
            }
          }
        }
      }
    }
  }

  .pageBox {
    &.news {
      .newsList {
        .page {
          .photoWrapper {
            height: 220px;

            @include rwd.breakpoint(tabletmobile) {
              height: auto;
            }
          }
        }
      }

      .pageBoxHeader {
        @include rwd.breakpoint(mobileUp) {
          margin-bottom: 70px;
        }

        @include rwd.breakpoint(mobile) {
          margin-bottom: 45px;
        }
      }

      li {
        @include rwd.breakpoint(mobilelandscape) {
          width: 50%;
        }
      }

      .txtTitle {
        @include rwd.breakpoint(mobileUp) {
          margin-bottom: 20px;
        }

        @include rwd.breakpoint(mobile) {
          margin-bottom: 12px;
        }
      }

      .mediaNewsContainer {
        .newsList {
          li {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .timeLineHero {
    @include mixin.flexValue();
    position: relative;
    margin: 15px 0 100px;

    .heroContent,
    .heroPhotoCover {
      @include rwd.breakpoint(mobileUp) {
        width: 50%;
      }

      @include rwd.breakpoint(mobile) {
        width: 100%;
      }
    }

    .heroContent {
      background-color: $c-seashell;

      @include rwd.breakpoint(mobileUp) {
        padding: 80px 70px 115px calc((100vw - 1280px) / 2);
      }

      @include rwd.breakpoint(mobile) {
        padding: 20px;
      }
    }

    .heroPhotoCover {
      @include mixin.bgCover();

      @include rwd.breakpoint(mobile) {
        height: 0;
        padding-bottom: 70%;
        max-height: 230px;
      }
    }

    .progressWrapper {
      @include mixin.position(absolute, calc(100% - 52px));
      @include mixin.centerer(true, false);

      .progressBg {
        @include mixin.flexCenter();
        @include mixin.makeCircle(104px, $c-white);
        margin: 0 auto;
      }

      .txtTitle {
        @include mixin.font(13px, 17px, 400, $c-silver);
        @include mixin.position(relative, -5px);
        display: inline-block;
        margin-top: 0;
        letter-spacing: 2px;
        font-family: "Poppins", sans-serif;
      }
    }

    .txtTitle {
      margin: 10px 0 0;
      font-family: "PT Serif", serif;

      @include rwd.breakpoint(mobileUp) {
        @include mixin.font(42px, 52px, 700, $c-mine-shaft-dark);
      }

      @include rwd.breakpoint(mobile) {
        @include mixin.font(28px, 38px, 700, $c-mine-shaft-dark);
      }
    }
  }

  .timeLineBody {
    .photoCard {
      margin-bottom: 30px;
      max-width: 100%;
      height: auto;
    }

    .row {
      @include rwd.breakpoint(mobile) {
        display: block;
      }
    }

    .content {
      padding: 15px 70px 0 0;
      color: $c-mine-shaft-dark;

      @include rwd.breakpoint(mobile) {
        padding-right: 0;
      }

      .txtLead {
        @include rwd.breakpoint(mobileUp) {
          @include mixin.font(24px, 37px);
          margin: 0 0 60px;
        }

        @include rwd.breakpoint(mobile) {
          @include mixin.font(20px, 30px);
          margin: 0 0 30px;
        }
      }

      .descriptionBox {
        @include rwd.breakpoint(mobileUp) {
          @include mixin.font(18px, 31px);
        }

        @include rwd.breakpoint(mobile) {
          @include mixin.font(16px, 26px);
        }

        ul {
          @include rwd.breakpoint(mobileUp) {
            margin: 0 0 50px;
            padding-left: 30px;
          }

          @include rwd.breakpoint(mobile) {
            padding-left: 20px;
            margin: 0 0 30px;
          }
        }

        a {
          color: $c-cardinal;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .boxTimeLine {
      background-color: $c-seashell;
      padding: 37px;

      .timeLineTitle {
        @include mixin.font(16px, 22px);
        letter-spacing: 2px;
        position: absolute;
        display: inline-block;
        padding-right: 25px;
        background-color: $c-seashell;
        padding: 40px 0 0 0;
        border-top: 2px solid $c-cardinal;
        margin-bottom: 0;
      }

      li {
        .iconStat {
          background-color: $c-seashell;
        }
      }
    }
  }

  .boxTimeLine {
    height: 100%;

    @include rwd.breakpoint(mobile) {
      padding: 20px;
      margin-top: 30px;
    }

    .displayItems {
      margin: 30px auto 0;
      display: block;
      min-width: inherit;
      width: 130px;
      padding: 9px;
    }

    .timeLineTitle {
      @include mixin.font(20px, 24px, false, $c-black);
      margin: 0;
    }

    li {
      margin-bottom: 15px;
      font-size: 0;
      background: url("../img/timeline-dot.png") repeat-y top 0 left 10px;
      position: relative;

      &:last-of-type {
        background: none;
      }

      & > .icon,
      & > div {
        display: inline-block;
        vertical-align: top;
      }

      &.hidden {
        display: none;
      }

      &.current {
        .txtLineTitle,
        .txtDate {
          color: $c-cardinal;
        }
      }

      div {
        width: 100%;
        padding-left: 40px;
      }

      .icon {
        color: $c-cardinal;
      }

      .iconStat {
        @include mixin.position(0, false, false, 0);
        background-color: $c-white;
        font-size: 14px;

        &.icon-loader {
          font-size: 24px;
        }

        &.icon-future {
          font-size: 20px;
        }

        &.icon-check-1,
        &.icon-check {
          left: 3px;
        }
      }

      .txtLineTitle {
        @include mixin.font(17px, 22px, false, $c-black);
        margin: 0 0 2px;
      }

      .docLnk {
        display: flex;
        align-items: flex-start;
        margin: 12px 0 20px;
        text-decoration: none;

        .icon {
          font-size: 20px;
          margin-right: 10px;
        }

        span {
          @include mixin.font(16px, 20px);
          transition: 0.3s;
          position: relative;
          left: 0;
        }

        &:hover {
          span {
            left: 8px;
            color: $c-cardinal;
          }
        }
      }
    }
  }

  .timelineNews {
    padding-bottom: 0;

    .newsList {
      padding-left: 0;
      margin: 0;
      justify-content: center;
    }

    .slick-dots {
      bottom: -50px;
    }

    @include rwd.breakpoint(mobile) {
      .slick-arrow {
        width: 40px;
        font-size: 22px;
        top: 93px;
      }

      .slick-arrow.slick-prev {
        left: -8px;
      }

      .slick-arrow.slick-next {
        right: -8px;
      }
    }
  }

  .filtersBar {
    .txtResult {
      @include mixin.position(absolute, false, 20px, 20px, false);

      @include rwd.breakpoint(mobile) {
        position: static;
        display: block;
        text-align: center;
        margin: 11px 0 10px;
      }
    }

    .cancelSearch {
      @include mixin.position(absolute, false, false, 18px, 20px);
      display: none;

      @include rwd.breakpoint(mobile) {
        position: static;
      }
    }

    #txtSearchAmount {
      font-weight: 700;
    }

    #searchPhrase {
      @include rwd.breakpoint(mobile) {
        margin-bottom: 25px;
      }
    }

    .searchForm {
      @include rwd.breakpoint(tabletmobile) {
        flex-wrap: wrap;
      }

      .txtFilter {
        @include mixin.font(20px, 24px);
        text-align: center;

        @include rwd.breakpoint(mobile) {
          margin-bottom: 20px;
          width: inherit;
          font-size: 17px;
        }

        .icon {
          font-size: 30px;
          margin-right: 10px;
        }
      }

      .searchFormBtn {
        padding: 0;
        margin: 0;
        display: flex;
        position: relative;
        z-index: 1;
        bottom: -1px;

        @include rwd.breakpoint(mobile) {
          width: 100%;
          margin: 0;
          display: flex;
          justify-content: space-between;

          li {
            width: 50%;
          }
        }

        button {
          @include mixin.flexCenter();
          background-color: $c-white;
          transition: 0.3s;
          cursor: pointer;
          outline: none;
          border: 1px solid transparent;
          border-bottom-color: $c-alto;

          @include rwd.breakpoint(mobileUp) {
            width: 200px;
            padding: 20px 10px;
          }

          @include rwd.breakpoint(mobile) {
            width: 100%;
            padding: 10px;
          }

          &:hover {
            .txtFilter {
              color: $c-cardinal;
            }
          }

          &.active {
            background-color: $c-catskill-white;
            border-color: $c-alto;
            border-bottom-color: $c-catskill-white;

            .txtFilter,
            .icon {
              color: $c-cardinal;
            }
          }

          .txtFilter {
            margin: 0;
            transition: 0.3s;
          }
        }
      }
    }

    .searchFormContent {
      background-color: $c-catskill-white;
      position: relative;
      border: 1px solid $c-alto;

      @include rwd.breakpoint(mobileUp) {
        padding: 20px 20px 63px;
      }

      @include rwd.breakpoint(mobile) {
        padding: 25px 15px 15px;
      }
    }

    .filterContent {
      margin-top: -5px;
    }

    .txtFilter {
      color: $c-black;
    }

    .txtLabel {
      display: block;
      color: $c-black;
      font-weight: 700;
      padding-left: 15px;
      margin-bottom: 5px;
    }

    .filterBox {
      margin-right: 15px;

      @include rwd.breakpoint(mobileUp) {
        flex: 1;
      }

      @include rwd.breakpoint(mobile) {
        margin: 0 0 15px;
        width: 100%;
        flex: auto;
      }

      &:last-of-type {
        margin-right: 0;
      }

      .selectWrapper {
        position: relative;
        display: block;

        .icon {
          top: 13px;
          right: 12px;
        }
      }

      select {
        @include mixin.font(14px, 18px);
        padding: 12px 32px 12px 15px;
        border-radius: 0;
        display: block;
        width: 100%;
      }
    }

    .searchBox {
      display: none;
      margin-top: -5px;

      label {
        display: block;
        width: 100%;
        position: relative;
      }

      select {
        border-radius: 0;
      }

      input {
        width: 100%;
        display: block;
        border: 1px solid $c-silver-chalice;
        padding: 13px 50px 13px 15px;

        &::-webkit-input-placeholder {
          /* Edge */
          color: $c-black;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $c-black;
        }

        &::placeholder {
          color: $c-black;
        }

        &:focus + .icon {
          color: $c-cardinal;
        }
      }

      .icon {
        @include mixin.position(absolute, false, 15px, 12px, false);
        color: $c-deep-sapphire;
        font-size: 20px;
        transition: 0.3s;
      }

      .usedFilters {
        @include rwd.breakpoint(mobileUp) {
          @include mixin.position(absolute, false, false, 18px, 20px);
        }

        @include rwd.breakpoint(mobile) {
          @include mixin.position(relative, false, false, auto, auto);
          margin-left: 0;
          flex-wrap: wrap;
          padding-top: 5px;
        }

        .txtFilter {
          @include mixin.font(14px, 18px);
          margin: 0;
          white-space: nowrap;

          @include rwd.breakpoint(mobile) {
            width: 100%;
            margin-bottom: 25px;
            text-align: left;
          }
        }

        ul {
          margin: 0;
          flex-wrap: wrap;
          @include rwd.breakpoint(mobileUp) {
            padding-left: 15px;
          }

          @include rwd.breakpoint(mobile) {
            padding-left: 0;
          }

          li {
            margin-right: 12px;

            @include rwd.breakpoint(mobile) {
              margin: 0 5px 5px 0;
              width: 100%;
            }
          }
        }
      }
    }

    button {
      @include mixin.font(12px, 16px);
      border: 1px solid $c-silver-chalice;
      border-radius: 0;
      padding: 5px 25px 3px 10px;
      position: relative;
      transition: 0.3s;
      cursor: pointer;
      background-color: $c-white;

      &.clearFilters,
      &.clearSearchFilters {
        background-color: $c-dove-gray;
        border-color: $c-dove-gray;
        color: $c-white;

        @include rwd.breakpoint(mobile) {
          @include mixin.position(absolute, 2px, 0);
        }

        &:hover {
          background-color: $c-cardinal;

          .icon {
            color: $c-white;
          }
        }
      }

      &.clearSearchFilters {
        @include rwd.breakpoint(mobile) {
          @include mixin.position(relative, -10px);
          display: block;
          margin: 0 0 0 auto;
        }
      }

      .icon {
        @include mixin.position(absolute, 4px, 7px);
        font-size: 16px;
        transition: 0.3s;
      }

      &:hover {
        border-color: $c-cardinal;

        .icon {
          color: $c-cardinal;
        }
      }
    }
  }

  .noResults {
    text-align: center;

    p {
      @include mixin.font(20px, 24px, 700);
    }

    button {
      &.clearFilters {
        @include mixin.font(14px, 18px, false, $c-white);
        background-color: $c-dove-gray;
        border: 1px solid $c-dove-gray;
        border-radius: 0;
        padding: 6px 35px 6px 15px;
        position: relative;
        transition: 0.3s;
        cursor: pointer;

        .icon {
          @include mixin.position(absolute, 4px, 10px);
          font-size: 21px;
          transition: 0.3s;
        }

        &:hover {
          background-color: $c-cardinal;
          border-color: $c-cardinal;

          .icon {
            color: $c-white;
          }
        }
      }
    }
  }

  .tasksBody {
    &.lastVer {
      .projectSingleBox {
        .topDetailsBar {
          .btn {
            cursor: pointer;
            border: none;
          }
        }

        .bottomDetails {
          .right {
            width: 100%;
          }
        }
      }
    }
  }

  .quartersFiltration {
    .displayMoreInfo {
      @include rwd.breakpoint(mobileUp) {
        display: none;
      }

      @include rwd.breakpoint(mobile) {
        display: inline-block;
      }
    }

    .descriptionBox {
      @include rwd.breakpoint(mobile) {
        padding-bottom: 55px;
        position: relative;
        height: 107px;
        overflow: hidden;

        &:before {
          @include mixin.position(absolute, false, false, 0, 0);
          content: "";
          width: 100%;
          height: 90%;
          background: -moz-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            white 100%
          );
          background: -webkit-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            white 100%
          );
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            white 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#ffffff",GradientType=0 );
          transition: 0.3s;
        }

        &.expanded {
          &:before {
            height: 55px;
          }
        }
      }
    }

    .videoWrapper {
      margin-bottom: 55px;

      iframe {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        border: none;
      }
    }
  }

  .quarters {
    .sectionTitle {
      @include mixin.font(38px, 46px, 700);
      padding-left: 0;
      text-transform: unset;
      &:before,
      &:after {
        display: none;
      }
    }
  }

  .descriptionBox {
    @include mixin.font(18px, 32px, false, $c-emperor);
    font-family: "Roboto", sans-serif;
    margin: 0 auto 50px;
    max-width: unset;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 100%;

    .sectionTitle {
      margin-bottom: 20px;
    }

    @include rwd.breakpoint(mobileUp) {
      @include mixin.font(18px, 32px);
    }

    @include rwd.breakpoint(mobile) {
      @include mixin.font(16px, 30px);
    }

    &:not(.small) {
      font-weight: 300;
    }

    &.small {
      color: $c-black;

      @include rwd.breakpoint(mobileUp) {
        @include mixin.font(15px, 25px);
      }

      @include rwd.breakpoint(mobile) {
        @include mixin.font(14px, 24px);
      }
    }

    .embed-container {
      margin-top: 30px;
    }

    .txtLead {
      @include mixin.font(22px, 34px, 700, $c-black);
    }

    .gallery-size-thumbnail {
      @include mixin.flexValue();

      &.gallery-columns-3 {
        justify-content: space-between;

        figure {
          width: calc((100% - 30px) / 3);
          margin-bottom: 15px;
        }
      }
    }

    picture > img {
      display: block;
      margin: 0 auto;
    }

    video {
      max-width: 100%;
    }

    iframe {
      max-width: 100%;

      &:not([src*="facebook.com"]) {
        @include rwd.breakpoint(mobile) {
          height: auto;
        }
      }
    }

    & > p > img {
      @include mixin.pushAuto;
      margin-bottom: 20px;
    }

    & > ul {
      &:not(.wp-block-gallery) {
        padding-left: 15px;

        li {
          padding-left: 20px;
          position: relative;
          margin-bottom: 8px;

          &:before {
            @include mixin.makeCircle(6px, $c-cardinal);
            @include mixin.position(absolute, 13px, false, false, 0);
            content: "";
          }
        }
      }
    }

    .content {
      ul {
        padding-left: 15px;

        li {
          padding-left: 20px;
          position: relative;
          margin-bottom: 8px;

          &:before {
            @include mixin.makeCircle(6px, $c-cardinal);
            content: "";
            position: absolute;
            top: 13px;
            left: 0;
          }
        }
      }
    }
  }

  .leftDistance {
    @include rwd.breakpoint(mobileUp) {
      padding-left: 30px;
    }
  }

  .txtLead {
    font-weight: 500;
    margin: 0 0 40px;
  }

  .displayMoreInfo {
    @include mixin.centerer(true, false);
    @include mixin.font(26px, 26px, false, $c-white);
    @include mixin.makeCircle(26px, $c-deep-sapphire);
    border-radius: 50%;
    bottom: 15px;
    color: $c-deep-sapphire;
    display: inline-block;
    font-size: 26px;
    position: absolute;
    text-align: center;
    transform: rotate(0deg);
    transform: translate(-50%, 0) rotate(0deg);
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $c-cardinal;
    }

    &.active {
      transform: translate(-50%, 0) rotate(180deg);
    }
  }

  .btn {
    display: inline-block;
    letter-spacing: 1px;
    padding: 14px 20px;
    text-align: center;
    
    @include rwd.breakpoint(mobileUp) {
      min-width: 195px;
    }

    @include rwd.breakpoint(mobile) {
        min-width: 150px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &:hover {
      background-color: $c-scarlet;
    }

    &.secondary {
      border: 1px solid $c-cardinal;
      color: $c-cardinal;
      background-color: transparent;

      &:hover {
        background-color: $c-cardinal;
        color: $c-white;
      }
    }
  }

  .txtCategoryLabel {
    .txtProjectData {
      white-space: normal;
    }
  }
} 