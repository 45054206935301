// SCSS Color Variables
// Named with this: http://chir.ag/projects/name-that-color

$c-white: #FFFFFF;
$c-black: #000000;

$c-alabaster: #FCFCFC;
$c-alto: #CFCFCF;
$c-amaranth-appr: #EA1E48;
$c-amaranth: #E51553;
$c-anakiwa: #89C9FF;
$c-astronaut: #2B2D77;
$c-astronaut-appr: #243368;
$c-athens-gray: #E7EAEE;
$c-azure-radiance: #0A82EC;
$c-babyblue: #0483CB;
$c-bahama-blue: #005C9A;
$c-biscay: #21366C;
$c-black-rock: #020728;
$c-blue-hot-news: #11254B;
$c-blue-ribbon: #006ce6;
$c-blue-zodiac: #11345E;
$c-blueborder: #6EA1E0;
$c-bombay: #B6B9BD;
$c-boston-blue: #3489C9;
$c-botticelli-appr: #CDD8E4;
$c-botticelli: #D5DDE8;
$c-boulder-appr: #757575;
$c-boulder: #7A7A7A;
$c-cadet-blue-appr: #a9b2c0;
$c-cadet-blue: #9B9EBF;
$c-catskill-white: #EDF1F6;
$c-cerise-red: #DA3C5C;
$c-chathams-blue: #175487;
$c-christi: #4A930E;
$c-cinnabar: #ED3836;
$c-cinnabar-app: #E6593A;
$c-cod-gray: #130e10;
$c-concrete-solid: #F2F2F2;
$c-concrete: #F3F3F3;
$c-congress-blue: #004a87;
$c-copper-rust: #9E4857;
$c-cornflower-blue: #2E77BA;
$c-cranberry: #DE4787;
$c-curious-blue: #0C7CAC;
$c-deep-sapphire: #0F2F74;
$c-deeps-sapphire: #093D6A;
$c-dodger-blue: #1D9BF0;
$c-dolly: #FFFB87;
$c-dove-gray: #656565;
$c-downriver: #092b49;
$c-dusty-gray: #999999;
$c-east-bay: #4C4D8A;
$c-emperor: #4F4F4F;
$c-flamingo: #f55a3a;
$c-french-pass: #BCE0FF;
$c-fuchsia-blue: #7758CA;
$c-gallery-appr: #EAEAEA;
$c-gallery: #EEEEEE;
$c-ghost: #C7C9D5;
$c-gray: #929292;
$c-graymist: #D2DAE6;
$c-green-leaf-dark: #2D5909;
$c-green-leaf: #34670A;
$c-gull-gray: #94a1ac;
$c-haiti: #121030;
$c-heather: #BDCAD6;
$c-holly: #001209;
$c-horizon: #5682A7;
$c-iron: #CED0D1;
$c-jumbo: #808081;
$c-la-palma: #17A026;
$c-lightning-yellow: #FCC41A;
$c-loblolly: #BEC5CA;
$c-mandy: #0e0d0d;
$c-matisse: #1E68A7;
$c-mercury-approx: #E6E6E6;
$c-mercury: #E5E5E5;
$c-midnight-blue: #00376A;
$c-mine-shaft-appr: #222222;
$c-mine-shaft-dark: #232323;
$c-mine-shaft: #333333;
$c-mirage: #1A2036;
$c-mischka: #CED3DA;
$c-mobster: #77737A;
$c-mystic: #DEE5EB;
$c-nevada: #697377;
$c-onahau: #D1F4FF;
$c-oslo-gray-appr: #8B969A;
$c-oslo-gray-new: #737373;
$c-oslo-gray: #919292;
$c-pale-sky: #697681;
$c-persimmon: #FC6D47; 
$c-pickled-bluewood: #283747;
$c-picton-blue: #379cf1;
$c-pigeon-post: #B9C8DB;
$c-pink-salmon: #FF95AB;
$c-pink: #ffc8d3;
$c-porcelain: #F3F4F5;
$c-red-orange: #FA3030;
$c-red: #FF0000;
$c-regal-blue: #00406A;
$c-river-bed: #4a5162;
$c-royal-blue: #3081EA;
$c-scarlet: #F52314;
$c-scooter: #30b5de;
$c-slate-gray: #6c768e;
$c-scooter: #30b5de;
$c-scorpion: #5F5F5F;
$c-ship-gray: #3E3D42;
$c-stratos: #000038;
$c-sunset-orange: #F95146;
$c-tabasco: #B21111;
$c-thunderbird: #C21E1C;
$c-tickle-me-pink: #ff7992;
$c-tree-poppy: #F89F18;
$c-trout: #484D5E;
$c-tundora: #444444;
$c-watercourse: #076D51;
$c-white-ice: #D6F0F8;
$c-wild-sand: #F5F5F5;
$c-limeade: #39B100;
$c-port-gore: #1E1E3F;
$c-silver: #cccccc;
$c-rolling-stone: #737677;
$c-solitude: #E1EFFF;
$c-silver-chalice: #B2B2B2;
$c-tower-gray: #AFBCC2;
$c-bright-gray: #313743;
$c-orange-peel: #EF9C00;
$c-fern: #65AB55;
$c-seashell: #F1F1F1;
$c-aqua-haze: #EDF1F5;
$c-abbey: #57585B;
$c-tango: #EC6811; 
$c-golden-fizz: #FFEF3A;
$c-cello: #203465;
$c-atlantis: #8FBF28;
$c-keppel: #38989C;
$c-flush-mahogany: #D04141;
$c-prussian-blue: #002344;
$c-malibu: #57AEFF;
$c-geyser: #D6DBE1;
$c-jelly-bean: #2781AC;
$c-cerulean: #00AADE;
$c-deep-cerulean: #007FA6;
$c-chambray: #3B5998;
$c-apple: #4DC247;
$c-bon-jour: #E4E3E4;
$c-endeavour: #0063AF;
$c-denim: #1292C9;
$c-fire-bush: #E6AA2E;
$c-scarpa-flow: #565662;
$c-mine-shaft-table: #383838;
$c-silver-chalice-table: #A7A7A7;
$-c-wtp-tory-blue: #1550B0;
$-c-wtp-big-stone: #1b2b42;
$c-president-bg-color: #2A357E;
$c-cardinal: #D31E3D;
$c-shiraz: #B30E2B;
$c-prezydent-kimberly: #7173A4;
$c-wild-watermelon: #FF4F6D;
$c-president-pale-sky: #6B7883;
$c-forest-green: #219c3c;
$c-dull-lavender: #91A3E3;
$c-waikawa-gray: #5A6BA9;

$c-wq-red: #F94144;
$c-wq-orange: #F8961E;
$c-wq-green: #85BB5B;

$c-water-silver: #C3C3C3;
$c-clementine: #E97000;
$c-gray: #808080;

$c-sea-green: #349D41;
$c-casper: #A4B7CC;
$c-sun: #FAB41A;
$c-copper-canyon: #7E4017;
$c-waste-geyser: #D5DDE6;
/* $c-curious-blue: #1BA2DC; */
$c-rose-of-sharon: #B15600;
$c-wate-mine-shaft: #272727;
$c-dark-tan: #6F0B1F;
$c-medium-purple: #675FDD;

$c-malibu-go: #61b2ff;
$c-dusty-gray-may: #959595;
